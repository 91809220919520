@import '/src/assets/css/responsiveness.scss';

table {
  border-collapse: collapse;
}

.table {
  background-color: transparent;
  background: linear-gradient(
    135deg,
    #ffffff 0%,
    rgba(255, 255, 255, 0.498825) 100%
  );
  border-radius: 10px;
  margin-bottom: 20px;
  opacity: 0.85;
  width: 100%;
  max-width: 100%;
}

.table th,
.table td {
  padding: 15px 30px;
  vertical-align: top;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  color: #000;
}

.table thead th {
  vertical-align: top;
  border-bottom: 0;
  padding: 25px 30px 5px;
}

.secondary {
  opacity: 0.5;
}

@import '/src/assets/css/variables.scss';

.alert {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  background-color: $color-red;
  margin: 0 0 10px 0;
  padding: 15px 15px 10px;
  text-align: center;

  &-success {
    @extend .alert;
    background-color: $color-green;
    font-size: $font-medium;
    font-weight: 600;
    line-height: 1.5;
    color: black;
  }
}

.alert-info {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.299497);
  border-radius: 15px;
  color: $color-orange;
  font-weight: 700;
  padding: 15px 20px;
}

@import '/src/assets/css/responsiveness.scss';

.heading {
  margin-bottom: 43px;
  padding-left: 25px;
  padding-right: 25px;
}

.heading-center {
  text-align: center;
}

.heading h1 {
  font-size: 40px;
  text-transform: uppercase;

  @include phone-large-breakpoint {
    font-size: 30px;
  }
}

.lead {
  font-size: 18px;
}

sup {
  vertical-align: super;
}

.flex-container {
  display: block;
}

@import '/src/assets/css/responsiveness.scss';

.container {
  height: 300px;
  width: 1319px;
  display: flex;

  @include phone-small-breakpoint {
    height: 200px;
    width: 100%;
  }

  @include phone-large-breakpoint {
    height: 250px;
    width: 100%;
  }
}

.loading-icon {
  justify-self: center;
  align-self: center;
  flex: 1;
}

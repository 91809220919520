@import '/src/assets/css/responsiveness.scss';

.loading {
  width: 80px;
  height: 80px;
}

.left-align {
  @include phone-small-breakpoint {
    text-align: left;
  }
}

@import '/src/components/Inputs/TextField/styles.module.scss';
@import '/src/assets/css/responsiveness.scss';

.error-wrapper {
  font-size: 14px;
  margin-bottom: 25px;
  color: #ff2642;
}

.text-field-container {
  padding-bottom: 10px;
}

.file-upload {
  display: none;
}

.file-wrapper {
  width: 100%;

  span {
    color: #8e8e8e;
  }
}

.order-id-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.icon-container-wrapper {
  display: flex;
  justify-content: center;
  height: 100px;
}

.icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include phone-large-breakpoint {
    max-width: 768px;
    margin: 0 auto;
  }

  @include phone-small-breakpoint {
    max-width: 362px;
    margin: 0 auto;
  }

  input,
  textarea {
    width: 100%;
    margin-bottom: 20px;

    @include phone-large-breakpoint {
      max-width: 768px;
    }

    @include phone-small-breakpoint {
      max-width: 362px;
    }
  }

  button {
    width: 100%;

    @include phone-large-breakpoint {
      max-width: 768px;
    }

    @include phone-small-breakpoint {
      max-width: 362px;
    }
  }
}

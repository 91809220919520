@import '/src/assets/css/responsiveness.scss';

.modal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: #000;
  transition: opacity 0.15s linear;
  border-radius: 20px;

  @include phone-large-breakpoint {
    overflow-x: hidden;
    overflow-y: auto;
  }

  @include phone-small-breakpoint {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .modal {
    background: rgba(0, 0, 0, 0.6);
    -webkit-backdrop-filter: blur(108px);
    backdrop-filter: blur(108px);
  }
}

.fade:not(.show) {
  opacity: 0;
}

.modal.show {
  display: block;
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition:
    transform 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
}

.modal-dialog {
  max-width: 730px;
  margin: 1.75rem auto;
  position: relative;
  width: auto;
  pointer-events: none;

  @include phone-large-breakpoint {
    margin: 0.5rem;
  }
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: hidden;
  overflow: scroll;
}

.modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

.modal-body {
  padding-left: 15px;
  padding-right: 15px;
  min-height: 700px;
}

.modal-body h1 {
  font-size: 40px;

  @include phone-large-breakpoint {
    font-size: 30px;
  }
}

.modal-header {
  position: relative;
  padding-top: 2vh;

  @include phone-large-breakpoint {
    font-size: 14px;
  }
}

.modal-close {
  display: flex;
  justify-content: flex-end;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0px 18px 0px 0px;
  background-color: transparent;
  cursor: pointer;

  @include phone-large-breakpoint {
    padding-top: 10px;
  }
}

.modal-close:focus {
  outline: none;
}

.modal-close svg {
  width: 14px;
  height: 14px;
}

.modal-sub-header {
  font-size: 23px;
}

.modal-header {
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 10px;
}

h2.modal-header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.modal-list {
  list-style: none;
  padding-left: 0;
  line-height: 2;
}

.modal-list li {
  margin-bottom: 5px;
  list-style-type: none;
}

.modal-list li a {
  display: inline-block;
  margin-right: 15px;
  text-decoration: underline;
}

.modal-list li:before {
  position: absolute;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: linear-gradient(135deg, #f37335 0%, #fdc830 100%);
  border-radius: 50%;
  margin-left: -20px;
  margin-top: 7px;
}

.text-center {
  text-align: center;
}

@media (max-width: 767.98px) {
  .header-nav:not(.show) {
    display: none;
  }
}

.header-nav.show:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: -1;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .header-nav.show:before {
    background: rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: none;
    backdrop-filter: none;

    @include phone-large-breakpoint {
      -webkit-backdrop-filter: blur(8px);
      backdrop-filter: blur(8px);
    }
  }
}

@import '/src/assets/css/responsiveness.scss';
@import '/src/assets/css/variables.scss';
.btn-group {
  position: relative;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-group .btn {
  font-weight: 500;
  border: 0;
  padding: 6px 20px 6px 0;
  font-size: 16px;
  line-height: 1.5;
  background-color: transparent;
  color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.7071 0.292893C12.0976 0.683417 12.0976 1.31658 11.7071 1.70711L6.70711 6.70711C6.51957 6.89464 6.26522 7 6 7C5.73478 7 5.48043 6.89464 5.29289 6.70711L0.292893 1.70711C-0.0976315 1.31658 -0.0976315 0.683417 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L6 4.58579L10.2929 0.292893C10.6834 -0.0976311 11.3166 -0.0976311 11.7071 0.292893Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
  background-position: center right;
  background-repeat: no-repeat;
}

.btn-group .btn img {
  margin-right: 7px;
  display: inline-block;
  vertical-align: top;
}

.btn-primary {
  border: 0;
  color: #000;
  background: $color-orange;
  box-shadow: 0px 5px 15px rgba(25, 230, 140, 0.15);
  border-radius: 11px;
  font-size: 16px;
  font-weight: 700;
  padding: 14px 16px;
  width: 300px;
}

@media (min-width: 360px) {
  .btn-primary {
    width: 325px;
  }
}

.btn-primary:hover,
.btn-primary:focus {
  color: #000;
  background: $color-green;
  text-decoration: none;
  outline: 0;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(25, 230, 140, 0.5);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.35;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.close {
  margin-left: auto;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 4px;
  background-color: transparent;
  cursor: pointer;

  width: auto;
}

.icon {
  display: inline-block;
  width: 17px;
  height: 17px;
  vertical-align: baseline;
  background-image: url(../../assets/images/icon_i.png);
  background-repeat: no-repeat;
  cursor: pointer;
}

.dropdown {
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 9px;
  vertical-align: middle;
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M15 1L8 8L1 1'/%3E%3C/svg%3E");
  margin-left: 20px;
  transition: transform 0.15s linear;
}

.accordion .title.active .dropdown {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion .title,
.accordion .accordion .title {
  cursor: pointer;
}

.accordion .title {
  font-size: 23px;
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer;
}

.accordion:last-child .title {
  border-bottom: 0;
}

.accordion .title.active {
  border-bottom-color: transparent;
}

.accordion .title .dropdown {
  display: inline-block;
  min-width: 16px;
  width: 16px;
  height: 9px;
  vertical-align: middle;
  background: no-repeat center center;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M15 1L8 8L1 1'/%3E%3C/svg%3E");
  margin-left: 20px;
  transition: transform 0.15s linear;
}

.accordion .title.active .dropdown {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.accordion .accordion .title {
  color: #f7931a;
  font-size: 16px;
  border-bottom: 0;
  padding: 7px 0;
}

.accordion .accordion .title:not(.active) .dropdown {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23f7931a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' d='M15 1L8 8L1 1'/%3E%3C/svg%3E");
}

.accordion .accordion .active.title {
  color: #fff;
}

.accordion .accordion .title ~ .content,
.accordion .title ~ .content {
  display: none;
}

.accordion .active.content,
.accordion .accordion .active.content {
  display: block;
}

.accordion .active.content {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 1rem;
}

.accordion .accordion .active.content {
  border-bottom: 0;
  padding-bottom: 0;
}

.accordion .accordion .content {
  padding-right: 25px;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 12px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.form-header {
  font-size: 23px;
  margin-bottom: 20px;
}

.text-warning {
  color: #f7931a;
}

.text-info {
  font-size: 12px;
}

.text-info a {
  color: #f7931a;
  text-decoration: underline;
}

$font-x-small: 8px;
$font-small: 12px;
$font-medium: 16px;
$font-large: 18px;

$font-header: 23px;

$color-red: #ff2642;
$color-orange: #f7931a;
$color-green: #17cf7e;

@import '/src/assets/css/responsiveness.scss';

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.25);
  margin-top: auto;
  padding: 20px 0;
  font-size: 13px;
  width: 100%;
}

.footer-menu {
  list-style: none;
  margin: 0 auto;
  padding-left: 0;
}

.footer-menu li {
  display: inline-block;
  padding: 0 15px;
  font-weight: 700;

  @include phone-large-breakpoint {
    padding: 7px 6px;
    font-weight: 500;
  }
}

.footer-menu a {
  display: block;
  color: #f7931a;
  text-decoration: underline;
}

.footer .container {
  position: sticky;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

footer,
section {
  display: block;
}

.container {
  width: 100%;
  max-width: 1266px;
  margin: 0 auto;
  padding: 0 25px;
}

.d-none {
  display: none !important;
}

.brand {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
  display: inline !important;
}

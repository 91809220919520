@import '/src/assets/css/responsiveness.scss';

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  background-clip: padding-box;
  background: #686868;
  box-sizing: border-box;
  display: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  max-width: 235px;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: break-word;

  border: 0.5px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.3rem;
  bottom: 100%;
  position: absolute;
  transform: translateX(-50%);
  left: 50%;

  &-active {
    display: block;
    width: 250px;
  }

  &-right-align {
    transform: translateX(0);
    right: 0;
    left: auto;
  }
}

.arrow {
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  right: 50%;

  &-right-align {
    right: 10px;
  }
}

.arrow:before {
  content: '';
  position: absolute;
  top: 100%;
  border-width: 5px;
  border-style: solid;
  border-color: #686868 transparent transparent transparent;
}

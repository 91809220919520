@import '/src/assets/css/responsiveness.scss';

@mixin details-inner {
  text-align: center;

  &-left-align {
    text-align: left;
  }
}

.form-action {
  padding: 10px 0;
  margin-bottom: 30px;
  text-align: center;
}

.error .form-action {
  padding-top: 10px;
}

.details-inner {
  @include details-inner();

  padding: 10px;
}

.details-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  @include phone-large-breakpoint {
    flex-direction: column;
    align-content: center;
  }
}

.details-col {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding-left: 15px;
  padding-right: 15px;

  @include phone-large-breakpoint {
    max-width: 100%;
    width: 70%;

    padding-left: 0;
    padding-right: 0;
  }
}

.details-row-columns .details-col-4 {
  width: 60%;
  float: left;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 31%;
  flex: 0 0 31%;
  max-width: 31%;
  float: none;
}

.details-row-columns .details-col-2 {
  width: 35%;
  float: right;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 19%;
  flex: 0 0 19%;
  max-width: 19%;
  float: none;
}

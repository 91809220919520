@import '/src/assets/css/responsiveness.scss';

.stepwizard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  font-size: 12px;
  margin-top: auto;
  margin-bottom: 8px;
  padding: 0 30px 30px;

  @include phone-large-breakpoint {
    padding: 0 25px 20px;
  }
}

.stepwizard-step {
  position: relative;
  width: 15px;
  padding-top: 24px;
  opacity: 0.4;
}

.stepwizard-step span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.stepwizard-step:before {
  content: '';
  position: absolute;
  width: 15px;
  height: 15px;
  border: 1px solid #fff;
  border-radius: 4px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.stepwizard-divider {
  border-top: 1px solid #fff;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-top: 7px;
  opacity: 0.4;
}

.stepwizard-step-complete span {
  opacity: 0.4;
}

.stepwizard-step-complete,
.stepwizard-step-active {
  opacity: 1;
}

.stepwizard-step-active:before {
  border-color: #f7931a;
}

.stepwizard-step-complete:before {
  border-color: #f7931a;
  background-color: #f7931a;
}

.stepwizard-step-complete + .stepwizard-divider {
  border-top-color: #f7931a;
  opacity: 1;
}

@mixin phone-small-breakpoint {
  @media only screen and (max-width: 362px) {
    @content;
  }
}

@mixin phone-large-breakpoint {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

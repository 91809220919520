@import '/src/assets/css/responsiveness.scss';
@import '/src/assets/css/variables.scss';

@mixin text-field-container {
  display: inline-block;
  margin: 0;
  width: 100%;
  height: calc(2em + 1rem + 4px);
  padding: 12px 18px;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.37);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}

\@import text-field-container .text-field-container {
  margin-bottom: 35px;
}

.text-field-input {
  @include text-field-container();
}

.remove-right {
  @include text-field-container();
  border-top-right-radius: 0; // Adjust the border radius
  border-bottom-right-radius: 0;
  border-right: none;
}

.remove-left {
  @include text-field-container();
  border-top-left-radius: 0; // Adjust the border radius
  border-bottom-left-radius: 0;
  border-left: none;
}

.text-field-container-push {
  margin-left: 50px;

  @include phone-large-breakpoint {
    .text-field-container-push {
      margin-left: 20px;
    }
  }
}

@media (min-width: 576px) {
  .text-field-container-right {
    -ms-flex-item-align: end;
    align-self: flex-end;
  }
}

.text-field-container-right input {
  text-align: right;
}

.text-field-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.recaptcha-field-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.text-field-label {
  display: block;
  font-weight: 500;
  font-size: $font-medium;

  @include phone-large-breakpoint {
    margin: 0;
  }
}

textarea.text-field-input {
  height: auto;
}

.text-field-input-code {
  letter-spacing: 12px;
}

.text-field-input-code::-webkit-input-placeholder {
  letter-spacing: 0;
}

.text-field-input-code::-moz-placeholder {
  letter-spacing: 0;
}

.text-field-input-code:-ms-input-placeholder {
  letter-spacing: 0;
}

.text-field-input-code::-ms-input-placeholder {
  letter-spacing: 0;
}

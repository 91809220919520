@import '/src/assets/css/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 0 20px;
  height: 80px;
}

.header-menu {
  padding: 10px;
  cursor: pointer;
}

.nav-link {
  color: #fff;
  font-weight: 700;
  font-size: $font-header;
  text-decoration: none;

  &.active {
    color: $color-orange;
  }
}

.header-logo {
  margin-right: auto;
  display: inline-block;
  padding: 10px 0;
  &-img {
    width: 129px;
    height: 30px;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
  }
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-top {
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  ul {
    font-family: 'Gilroy', sans-serif;
    color: #fff;
    list-style: none;
    line-height: 3;
    font-size: 25px;
  }

  li {
    position: relative;
  }

  // renders a circle in line with the active link
  .nav-link.active::after {
    content: '';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #f7931a;
  }
}

.modal-bottom {
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
}

.modal-close {
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 30px;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.modal-close:focus {
  outline: none;
}

.modal-close svg {
  width: 20px;
  height: 20px;
}

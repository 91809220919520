@import '/src/assets/css/responsiveness.scss';

.head-info {
  background: url(../../../../assets/images/pattern.png),
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px 20px 0 0;
  padding: 20px 30px;

  @include phone-large-breakpoint {
    padding: 20px 25px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .head-info {
    -webkit-backdrop-filter: blur(108px);
    backdrop-filter: blur(108px);
  }
}

.order-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

  li {
    display: flex;
    flex-direction: row;
  }

  @include phone-large-breakpoint {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      width: 100%;
    }
  }
}

.order-list li {
  padding-right: 30px;

  @include phone-small-breakpoint {
    display: flex;
    align-items: center;
  }
}

.order-list .item-title {
  display: flex;
  align-items: flex-start;
  gap: 5px;

  .item-title:after {
    content: none;
  }
}

.item-title:after {
  content: ' ';
  display: inline;
}

.order-list .item-desc {
  opacity: 0.4;
  display: block;
}

.tooltip {
  width: 15px;
  height: 15px;
}

.countdown {
  @extend .tooltip;
}

.wallet-address {
  text-decoration: underline;
  cursor: pointer;
}

.info-title {
  font-size: 23px;
  font-weight: 700;
  display: inline-block;
  margin-right: 10px;
}

.info-desc {
  font-size: 23px;
  font-weight: 500;
}

@import '/src/assets/css/responsiveness.scss';

.container-wrapper {
  padding-top: 5vh;
}

.content {
  height: 500px;
}

.inner-content {
  padding: 20%;
}

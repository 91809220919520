@import '/src/assets/css/responsiveness.scss';

.radio-field {
  position: relative;
  display: flex;
}

.radio-field-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}

.radio-field-input-wrapper {
  position: relative;
  width: 20px;
  height: 20px;
  border: none;
  background: none;
}

.radio-field-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  pointer-events: none;
  content: '';
  background: rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 5px;
}

.radio-field-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 20px;
  height: 20px;
  content: '';
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .radio-field-input:checked ~ .radio-field-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.radio-field-input:focus ~ .radio-field-label::before {
  box-shadow:
    0 0 0 1px #fff,
    0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.radio-field-label {
  margin-bottom: 0;
  margin-left: 32px;
}

.radio-field-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}

.radio-field-button .radio-field-label::before {
  border-radius: 50%;
}

.radio-field-button .radio-field-label::after {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  margin-left: 3px;
}

.radio-field-button .radio-field-input:checked ~ .radio-field-label::before {
  border-color: #fff;
}

.radio-field-button .radio-field-input:checked ~ .radio-field-label::after {
  background-color: #f7931a;
}

@import '/src/assets/css/responsiveness.scss';

header {
  display: block;
}

.header {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  z-index: 1000;
}

.header-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  max-width: 1266px;
  margin: 0 auto;
  padding: 0 25px;
}

.header .container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-logo {
  margin-right: auto;
  display: inline-block;
  padding: 10px 0;
  &-img {
    width: 129px;
    height: 30px;
  }
}

.header-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;

  @include phone-large-breakpoint {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
    background-color: #000;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.29);
    border-radius: 0px 0px 20px 20px;
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

@include phone-large-breakpoint {
  .header-nav:not(.show) {
    display: none;
  }
}

.header-nav.show:before {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  z-index: -1;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .header-nav.show:before {
    background: rgba(0, 0, 0, 0.15);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
  }
}

.header-nav:before {
  display: none;
  content: none;
}

.header-nav ul {
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-left: auto;

  @include phone-large-breakpoint {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.header-nav .nav-link {
  font-size: 16px;
  margin: 0 25px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  display: block;
  position: relative;

  @include phone-large-breakpoint {
    margin: 10px 0;
    font-size: 23px;
  }
}

.header-nav .nav-link:hover,
.header-nav .nav-link:focus,
.header-nav .nav-link.active {
  color: #f7931a;
}

.header-nav .nav-link:before {
  content: none;
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 0;
  top: 50%;
  margin-top: -4px;

  @include phone-large-breakpoint {
    content: none;
  }
}

.header-nav .nav-link:active:before {
  background: linear-gradient(135deg, #f37335 0%, #fdc830 100%);
}

@import '/src/assets/css/responsiveness.scss';
@import '/src/assets/css/variables.scss';

.amount-screen-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px 30px 0;

  @include phone-large-breakpoint {
    flex-direction: column;
    justify-content: center;
    padding: 15px 25px 0;
  }
}

.amount-screen-header-title {
  font-size: 23px;
  font-family: 'Gilory', sans-serif;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 15px;
}

.amount-screen-header-right {
  text-align: right;
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 1;
  order: 1;

  @include phone-large-breakpoint {
    text-align: center;
  }
}

.amount-screen-header-title {
  margin-bottom: 10px;
  text-align: center;
}

.amount-screen-body {
  min-height: 203px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 680px;
  min-width: 90%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 5px;

  @include phone-large-breakpoint {
    align-items: center;
    width: 335px;
    height: 105px;
    max-height: 105px;
    min-height: 105px;
    padding: 10px 5%;
    gap: 100px;
  }
}

.amount-screen-body-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: -1;
  @include phone-large-breakpoint {
    width: 335px;
  }
}

.error .amount-screen-body .svg-opacity {
  opacity: 1;
}

.error .amount-screen-body .svg-fill {
  fill: #ff2642;
}

.amount-screen-body label {
  display: block;
  font-weight: 500;
  font-size: 20px;
  @include phone-large-breakpoint {
    font-size: 16px;
  }
}

.amount-screen-body input {
  font-weight: 500;
  background-color: transparent;
  border: 0;
  display: block;
  width: 100%;
  line-height: 1;
  padding: 0.25rem 0;
  color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  min-height: 55px;
  font-size: 40px;
  max-width: 230px;

  @include phone-large-breakpoint {
    min-height: 0px;
    font-size: 16px;
  }
}

.amount-screen-body .input-error {
  border: 2px solid $color-red;
}

.amount-screen-body input::-webkit-input-placeholder {
  color: #fff;
  opacity: 0.4;
}

.amount-screen-body input::-moz-placeholder {
  color: #fff;
  opacity: 0.4;
}

.amount-screen-body input:-ms-input-placeholder {
  color: #fff;
  opacity: 0.4;
}

.amount-screen-body input::-ms-input-placeholder {
  color: #fff;
  opacity: 0.4;
}

.amount-screen-body input:disabled {
  opacity: 0.4;
}

.amount-screen-body input:focus {
  border-color: #f7931a;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(25, 230, 140, 0.25);
}

.amount-screen-body .form-group-left {
  width: 100%;
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  & input {
    text-align: start;
    margin-bottom: 0;
  }

  @include phone-large-breakpoint {
    grid-gap: 10px;
    justify-content: flex-start;
    height: 75px;
  }
}

.input-subtext {
  font-size: $font-small;
  margin: 0;
  color: #fff;
  opacity: 0.4;
  margin-top: 5px;

  @include phone-large-breakpoint {
    font-size: $font-x-small;
  }
}

.amount-screen-body .form-group-right {
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  @include phone-large-breakpoint {
    grid-gap: 10px;
    justify-content: flex-start;
    height: 75px;
  }

  & input {
    text-align: right;
    margin-bottom: 0;
  }
}

.error-text {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: $color-red;
  display: block;
}

p.error {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  color: $color-red;
}

.amount-screen-body .form-group {
  margin-bottom: 0;

  @include phone-large-breakpoint {
    margin-left: 20px;
  }
}

.auth-form-trigger {
  text-align: center;
  margin-bottom: 15px;
}

.loading {
  width: 100px;
  height: 100px;

  &-small {
    width: 40px;
    height: 40px;

    @include phone-large-breakpoint {
      width: 20px;
      height: 20px;
    }
  }
}

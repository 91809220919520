@import '../../assets/css/responsiveness.scss';

.main {
  display: block;
}

.layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.container {
  width: 100%;
  max-width: 1266px;
  margin: 0 auto;
  padding: 0 25px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.window-inner {
  padding: 25px;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 30px 30px;
}

.how-to-block {
  margin-top: auto;
  margin-bottom: 60px;
  display: none;
  position: absolute;
  bottom: 40;
  width: 100%;
}

@media (min-width: 576px) {
  .how-to-block {
    display: block;
  }
}

.display-title {
  font-size: 40px;
  font-weight: 900;
  display: block;
  position: relative;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='54' height='8' viewBox='0 0 54 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle opacity='0.5' cx='4' cy='4' r='4' fill='%23fff'/%3e%3ccircle opacity='0.5' cx='27' cy='4' r='4' fill='%23fff'/%3e%3ccircle opacity='0.5' cx='50' cy='4' r='4' fill='%23fff'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: bottom center;
  padding-bottom: 50px;
}
.form-content {
  position: relative;
  background: url(../../assets/images/pattern.png),
    linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.05) 100%
    );
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 740px;
  border-radius: 20px;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .form-content::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

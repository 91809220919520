@import '/src/assets/css/responsiveness.scss';

.page-container {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 25px;
  max-width: 780px;
  padding-left: 15px;
  padding-right: 15px;

  @include phone-large-breakpoint {
    max-width: 375px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
  }
}

.form-search {
  position: relative;
  margin-bottom: 20px;
}

.icon {
  position: absolute;
  top: 18px;
  left: 18px;
}

.form-search .field-input {
  padding-left: 50px;
}

.field-input {
  display: block;
  width: 100%;
  height: calc(2em + 1rem + 4px);
  padding: 12px 18px;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.37);
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 10px;
}

textarea.field-input {
  height: auto;
}

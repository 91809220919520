@import '/src/assets/css/responsiveness.scss';
@import '../../../components/Inputs/TextField/styles.module.scss';

@mixin field-green {
  color: #f7931a;
  font-weight: 700;
  text-decoration: underline;
}

.container {
  margin: 10px;

  label {
    padding-bottom: 10px;
  }
}

.field-green {
  @include field-green();
}

.btc-order-input .lightning-order-input {
  @include text-field-container();
  @include field-green();
  margin-bottom: 0.5rem;
}

.payment-details {
  @include text-field-container();
  @include field-green();
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.view-transaction-link {
  padding: 15px 0 15px 0;
}

.view-txn {
  display: flex;
  justify-content: center;
}

.left-align {
  @include phone-small-breakpoint {
    text-align: left;
  }
}

/**
* Primitives Start
*/
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #f7931a;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #09c673;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 700;
  line-height: 1.2;
  color: inherit;
}

h1,
.h1 {
  font-size: 40px;
  font-weight: 900;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

body {
  color: #fff;
  font-family: 'Gilory', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  background-color: #000;
  background-image: url(../images/bg.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/**
* Primitives End
*/

/**
* Fonts Start
*/

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-black-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-bold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-extrabold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-medium-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-regular-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilory';
  src:
    url('../fonts/gilroy-semibold-webfont.woff2') format('woff2'),
    url('../fonts/gilroy-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/**
* Fonts End
*/

/**
* Mapbox Start
*/

.mapbox-holder {
  position: relative;
}

.mapbox {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  margin-top: -15px;
}

@media (min-width: 768px) {
  .mapbox {
    margin-top: 0;
  }
}

.mapbox::before {
  display: block;
  content: '';
  /*padding-top: 56.25%;*/
}

.mapbox .embed-responsive-item,
.mapbox iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 20px;
}

.mapboxgl-ctrl-top-right {
  position: static;
}

@media (min-width: 768px) {
  .mapboxgl-ctrl-top-right {
    right: 0;
    position: absolute;
    pointer-events: none;
    top: 0;
    right: 0;
    left: auto;
    z-index: 2;
  }
}

.mapboxgl-popup {
  max-width: 315px;
}

.mapboxgl-popup-content {
  color: #fff;
  border-radius: 15px;
  background: #171716;
  z-index: 1000;
  padding: 45px 25px 10px;
  font-size: 13px;
}

@media (min-width: 768px) {
  .mapboxgl-popup-content {
    padding-top: 25px;
  }
}

.mapboxgl-popup-close-button {
  font-size: 2.5rem;
  color: #fff;
  position: absolute;
  line-height: 1;
  padding: 4px 12px;
  display: inherit;
  border: 0;
  border-radius: 0 3px 0 0;
  cursor: pointer;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .mapboxgl-popup-close-button {
    position: static;
    margin-top: -10px;
  }
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #171716;
}

.mapboxgl-popup-content .subHeader {
  font-size: 16px;
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 5px;
}

.mapboxgl-popup-content .link .subHeader {
  padding-top: 0;
  padding-bottom: 0;
  display: inline;
  font-size: 13px;
}

.mapboxgl-popup-content .link {
  text-decoration: underline;
}

.mapboxgl-popup-content p {
  color: rgba(255, 255, 255, 0.4);
}

.mapboxgl-popup {
  min-width: 300px !important;
  max-width: 315px !important;
}

/**
* Mapbox End
*/

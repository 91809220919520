@import '/src/assets/css/responsiveness.scss';

.file-upload-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  cursor: pointer;
}

.file-input-field {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  cursor: pointer;
}

.file-input-field-canvas {
  height: 100%;
  background: rgba(0, 0, 0, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  text-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 50px;
}

.file-input-field-canvas.filled {
  padding: 0;
  max-width: 323px;
}

.file-input-field.valid {
  border-color: #f7931a;
}

.file-input-field.invalid {
  border-color: #ff2642;
}

.file-input-field.progress {
  border-color: #f7931a;
}

.file-input-field span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-input-field .input-file {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  max-width: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  direction: ltr;
  cursor: pointer;
}

.file-input-field .close {
  margin-left: auto;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 4px;
  background-color: transparent;
  cursor: pointer;
}

.error {
  opacity: 1;
  fill: #ff2642;
  display: block;
}

p.error {
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
  color: #ff2642;
}

.instruction-img {
  width: 325px;
  height: 375px;

  @include phone-large-breakpoint {
    width: auto;
    height: auto;
    max-width: 80%;
  }
}

.preview-img {
  width: 100%;
  height: 100%;

  @include phone-large-breakpoint {
    width: auto;
    height: auto;
    max-width: 80%;
  }
}

.submit-btn {
  text-align: center;
}

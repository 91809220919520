@import '../Layout/Form/styles.module.scss';

.container {
  display: flex;

  select {
    width: 100px;

    &:nth-child(1) {
      margin-right: 0px;
    }

    &:nth-child(2) {
      margin-left: 0px;
    }
  }
}

.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.alert-info {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.299497);
  border-radius: 15px;
  color: #f7931a;
  font-weight: 700;
  padding: 15px 20px;
}

.icon-payment {
  margin-right: 0;
  width: 88%;
}

a {
  text-decoration: underline;
}

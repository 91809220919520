@import '/src/assets/css/responsiveness.scss';

.pt-10 {
  padding-top: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.left-align {
  @include phone-small-breakpoint {
    text-align: left;
  }
}

@import '/src/assets/css/responsiveness.scss';

.phone-options {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.phone-options-item {
  display: inline-block;
}

.phone-options-item:not(:last-child) {
  margin-right: 16px;
}

.sent-phone-label {
  margin-bottom: 10px;

  @include phone-large-breakpoint {
    text-align: center;
  }
}

.field-input-code {
  letter-spacing: 12px;
}

.field-input-code::-webkit-input-placeholder {
  letter-spacing: 0;
}

.field-input-code::-moz-placeholder {
  letter-spacing: 0;
}

.field-input-code:-ms-input-placeholder {
  letter-spacing: 0;
}

.field-input-code::-ms-input-placeholder {
  letter-spacing: 0;
}

.action-row {
}

@import '/src/assets/css/responsiveness.scss';

@mixin form-row {
  @include phone-large-breakpoint {
    padding: 0;
  }
}

.form-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  @include phone-large-breakpoint {
    gap: 10px;
  }
}
.form-row {
  @include form-row();

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.form-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  @include phone-large-breakpoint {
    gap: 10px;
  }
}

.small-column {
  flex: 1.5;
}

.large-column {
  flex: 2.5;

  @include phone-large-breakpoint {
    flex: content;
  }
}

.form-object {
  margin-right: 50px;
  display: block;

  @include phone-large-breakpoint {
    display: none;
  }
}
